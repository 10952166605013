<template>
    <div>
        <v-alert
            border="right"
            colored-border
            color="secondary"
            type="info"
            elevation="1"
        >
        {{ title }}
        <div style="min-height:10px;"></div>
        <ul>
            <li>{{ text1 }}</li>
            <li>{{ text2 }}</li>
        </ul>
        </v-alert>
    </div>
</template>

<script>

export default {
    name: 'info-user-type-domacin',
    data: () => ({
        title: 'Izbrani tip kartice Domačin:',
        text1: "v lasti ali službeni uporabi fizične osebe s stalnim prebivališčem v Občini Bled, ki za njegovo uporabo plačuje predpisano boniteto, kar izkaže v postopku izdaje parkirne dovolilnice",
        text2: "je v uporabi fizične osebe s stalnim prebivališčem v Občini Bled na podlagi pogodbe o lizingu oziroma pogodbe o poslovnem najemu"
    })
}

</script>